/* Can't style the html tag with theme-ui, so put styles in here */

html {
  height: 100%;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

th, td {
  padding-inline-start: 10px !important;
}